export default (data = {}) => {
    return {
        item_code: null,
        item_concept: null,
        item_note: null,
        item_reference: null,
        item_value: 0,
        discount: 0,
        quantity: 1,
        price: 0,
        ...data
    }
}